import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AssistanceSection, {
    useAssistanceSectionContext,
} from '../../../generic_document/pages/Assistance/AssistanceSection';
import { ItemMoreActionsButton } from '../../../generic_document/pages/Assistance/ItemDataSection';
import { TroxOrderCodeContextProvider } from './TroxOrderCodeContext';
import DropdownMenu from '../../../core_updated/components/DropdownMenu';
import {
    ItemDataFieldGroupProps,
    useItemDataFieldGroupProps,
} from '../../../generic_document/pages/Assistance/customizable/ItemDataFieldGroup';
import { useAssistanceViewContext } from '../../../generic_document/pages/Assistance/AssistanceViewContext';
import { useAssistanceContext } from '../../../generic_document/pages/Assistance/AssistanceContext';
import Tooltip from '../../../core/components/Tooltip';
import classnames from 'classnames';
import { withIcon } from '../../../core_updated/components/Icon';
import { faCrosshairs } from '@fortawesome/pro-regular-svg-icons';

const FocusIcon = withIcon(faCrosshairs);

const TroxItemFieldGroup = (props: ItemDataFieldGroupProps) => {
    const { t } = useTranslation('assistance');
    const { isAssistanceNeeded } = useAssistanceContext();
    const { onFieldAction, previewRef } = useAssistanceViewContext();
    const { viewMode } = useAssistanceSectionContext();
    const fieldGroupProps = useItemDataFieldGroupProps(props);
    const { children, fields, itemIndex } = props;

    // if orderCodes are being used, hasOrderCode is passed down and activates a showOrderCodeToggle
    // The initial value for selectedArticleField is determined in the backend and stored on the orderCode field
    const orderCodeField = fields.find((field) => field.fieldName === 'order_code');
    const [troxSelectedArticleField, setTroxSelectedArticleField] = useState(
        orderCodeField?.data?.isDisplayed === false ? 'articleNumber' : 'orderCode'
    );

    const handleActionClick = (action: string, payload = undefined) => {
        // For selectOrderCode and selectArticleNumber actions we update SelectedArticleField before calling the backend
        // TODO: this shouldn't be a separate command for each field, but the field name in payload
        if (action === 'selectOrderCode') {
            setTroxSelectedArticleField('orderCode');
        } else if (action === 'selectArticleNumber') {
            setTroxSelectedArticleField('articleNumber');
        }

        return onFieldAction({ itemIndex, action: `action:${action}`, payload });
    };

    const handleFocusActionClick = () => {
        const triggers = previewRef?.current.querySelectorAll('.image-map__area--row');
        const trigger = triggers?.[itemIndex];
        if (!trigger) return;

        const triggerPos = trigger.getBoundingClientRect();
        const parentPos = previewRef?.current.getBoundingClientRect();

        previewRef?.current.scrollTo({
            top: triggerPos.top + previewRef?.current.scrollTop - parentPos.top,
            behavior: 'smooth',
            block: 'start',
        });
    };

    return (
        <TroxOrderCodeContextProvider
            troxSelectedArticleField={troxSelectedArticleField}
            onTroxSelectedArticleFieldChange={setTroxSelectedArticleField}
        >
            <AssistanceSection.FieldGroup
                {...fieldGroupProps}
                controls={
                    <>
                        <Tooltip content={t(`assistance:itemsView.actions.focus`)} placement="top">
                            <AssistanceSection.FieldGroupIconButton
                                onClick={handleFocusActionClick}
                                className={classnames(viewMode !== 'list' && 'w-8 h-8 -mr-1')}
                            >
                                <FocusIcon />
                            </AssistanceSection.FieldGroupIconButton>
                        </Tooltip>

                        <ItemMoreActionsButton
                            onActionClick={handleActionClick}
                            itemIndex={itemIndex}
                            fieldNames={fields.map((formField) => formField.fieldName)}
                            showOrderCodeToggle={orderCodeField}
                            disabled={!isAssistanceNeeded}
                        >
                            <DropdownMenu.Item
                                onClick={() => handleActionClick('selectOrderCode')}
                                disabled={troxSelectedArticleField === 'orderCode'}
                            >
                                {t('itemsView.actions.selectOrderCode')}
                            </DropdownMenu.Item>

                            <DropdownMenu.Item
                                onClick={() => handleActionClick('selectArticleNumber')}
                                disabled={troxSelectedArticleField === 'articleNumber'}
                            >
                                {t('itemsView.actions.selectArticleNumber')}
                            </DropdownMenu.Item>

                            <DropdownMenu.Separator />
                        </ItemMoreActionsButton>
                    </>
                }
            >
                {children}
            </AssistanceSection.FieldGroup>
        </TroxOrderCodeContextProvider>
    );
};

export default TroxItemFieldGroup;
